import React from "react";

function NotFoundPage(props) {
  return (
    <>
      <div className="notfound">
        <h1>持續更新中...</h1>
      </div>
    </>
  );
}

export default NotFoundPage;
